<template>
  <div>

    <!-- Table to display all teams and their attributes -->
    <v-container>
    <h2 class="ma-2">Hur fan går det för alla???</h2>
      <v-data-table
        :headers="teamHeaders"
        :items="teams"
        item-key="t_id"
        class="elevation-1"
      >
        <template v-slot:item.t_ts_lunch_in="{ item }">
          <span>{{ item.t_ts_lunch_in ? new Date(item.t_ts_lunch_in).toLocaleTimeString() : "" }}</span>
        </template>

        <template v-slot:item.t_ts_lunch_out="{ item }">
          <span>{{ item.t_ts_lunch_out ? new Date(item.t_ts_lunch_out).toLocaleTimeString() : "" }}</span>
        </template>

        <template v-slot:item.t_ts_start="{ item }">
          <span>{{ item.t_ts_start ? new Date(item.t_ts_start).toLocaleTimeString() : "" }}</span>
        </template>

        <template v-slot:item.t_ts_finish="{ item }">
          <span>{{ item.t_ts_finish ? new Date(item.t_ts_finish).toLocaleTimeString() : "" }}</span>
        </template>

        <template v-slot:item.raceTime="{ item }">
          <span>{{ getRaceTime(item) }}</span>
        </template>

        <template v-slot:item.t_dished="{ item }">
          <span>{{ item.t_dished ? "Ja": "Nej" }}</span>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: 'RallyProgress',
  data: () => ({
    teams: [],
    teamHeaders: [
      { text: 'Startnummer', value: 't_start_position' },
      { text: 'Lagnamn', value: 't_name' },
      { text: 'Lagledare', value: 't_leader' },
      { text: 'Telefonnummer', value: 't_phone' },
      { text: 'Antal stationer', value: 't_unlocks' },
      { text: 'Gick på lunch kl', value: 't_ts_lunch_in' },
      { text: 'Avslutade lunch kl', value: 't_ts_lunch_out' },
      { text: 'Starttid', value: 't_ts_start' },
      { text: 'Måltid', value: 't_ts_finish' },
      { text: 'Körtid', value: 'raceTime' },
      { text: 'Diskad?', value: 't_dished' }


    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(["getAllTeams"]),
    async initialize() {
      const teams = await this.getAllTeams();
      teams.sort((a, b) => a.t_start_position - b.t_start_position);
      this.teams = teams;
    },
    getRaceTime(team) {
      const startTime = team.t_ts_start ? new Date(team.t_ts_start) : new Date();
      const endTime = team.t_ts_finish ? new Date(team.t_ts_finish) : new Date();
      const lunchStartTime = team.t_ts_lunch_in ? new Date(team.t_ts_lunch_in) : new Date();
      const lunchEndTime = team.t_ts_lunch_out ? new Date(team.t_ts_lunch_out) : new Date();
      
      let raceTime = endTime - startTime - (lunchEndTime - lunchStartTime);
      
      const raceHours = Math.floor(raceTime / 3600000);
      const raceMinutes = Math.floor((raceTime - raceHours * 3600000) / 60000);
      const raceSeconds = Math.floor((raceTime - raceHours * 3600000 - raceMinutes * 60000) / 1000);

      return `${this.padTo2Digits(raceHours)}:${this.padTo2Digits(raceMinutes)}:${this.padTo2Digits(raceSeconds)}`;
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    }
  }
};
</script>

<style>

</style>
